import React, { useState,useEffect} from "react";
import Routes from "./Routes";
import { Link ,withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import axios from 'axios';
import {useWindowSize} from '@react-hook/window-size'
import logo from './images/logo.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App(props) {
  const [width, height] = useWindowSize()
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  //const SERVER_URL = "http://localhost/designtent";
  const SERVER_URL = "https://api.pirelli-loyalty.odh.gr/loyalty";
  useEffect(() => {
    setIsOnline(window.navigator.onLine);

    window.addEventListener('online', () => {
      setIsOnline(true);
    });
    
    window.addEventListener('offline', () => {
      setIsOnline(false);
      //console.log('offline');
    });
   
    if(localStorage.getItem("access_token")){
      
      userHasAuthenticated(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
    }
   
    setIsAuthenticating(false);
  }, []);
  
 
  function handleLogout() {
    userHasAuthenticated(false);
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    const data={};
    axios.post(`${SERVER_URL}/logout`, data);
    delete axios.defaults.headers.common["Authorization"];
     /*
//response.user
{"Fullname":"Test Dealer","Firstname":"Test","Lastname":"Dealer","Email":"","Username":"testdealer"}
          */
    //let user=JSON.parse(localStorage.getItem('user'));
    
    props.history.push("/login");

  }
  return (
    !isAuthenticating &&
    <div className="App container" style={{width:`${width}px`,height:`${height}px`}}>

      <div className="spin-container" style={{display:isLoading?'block':'none'}}>
      <div className="loader">Loading...</div>
      </div>
         <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/"><img   src={logo} style={{width:`100px`}}  alt='Pirelli'/></Link>
           
          </Navbar.Brand>
          <Navbar.Toggle />
          {!isOnline
      ?<div className="offline-notice" >ΕΚΤΟΣ ΣΥΝΔΕΣΗΣ!</div>
      :null
      }
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>

          {isAuthenticated
            ? <NavItem onClick={handleLogout}>Αποσύνδεση</NavItem>
            : null
          }




          </Nav>
        </Navbar.Collapse>
      </Navbar>




     
      
      <Routes appProps={{ isAuthenticated, userHasAuthenticated,setIsLoading,SERVER_URL,isOnline}} />
      </div>
  );
}

export default withRouter(App);
